<template>
  <div class="container">
    <div class="datepicker-container">
      <v-date-picker
        v-model="picker"
        range
        first-day-of-week="1"
        show-week
        :allowed-dates="allowedDates"
        color="green"
        @input="onChangeDates"
      />
    </div>
  </div>
</template>

<script>
import { useRulesStore } from "../store.pinia.js";

export default {
  name: "Dates",
  data() {
    return {
      picker: [],
      store: useRulesStore(),
    };
  },
  methods: {
    allowedDates: (x) => {
      return new Date(x) < Date.now();
    },
    fixPicker(x) {
      if (x.length !== 2) {
        return;
      }
      let dates = [new Date(this.picker[0]), new Date(this.picker[1])];
      if (dates[0] > dates[1]) {
        dates = [dates[1], dates[0]];
      }
      dates = [
        new Date(dates[0].setDate(dates[0].getDate() - dates[0].getDay() + 1)),
        new Date(dates[1].setDate(dates[1].getDate() + 7 - dates[1].getDay())),
      ];  
      this.picker = [
        dates[0].toISOString().substr(0, 10),
        dates[1].toISOString().substr(0, 10),
      ];
    },
    onChangeDates(x) {
      this.fixPicker(x);
      this.$emit("change", this.picker);
    },
  },
  mounted() {
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
}
.datepicker-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
