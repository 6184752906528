<template>
  <div class="text-container">
    <pre>
      {{ header }}
        {{ store.analysys.unique.length }} unique users
        {{ store.analysys.regular.length }} regular users 
        {{ store.analysys.active.length }} active users
        {{ store.analysys.active2.length }} very active users
    </pre>
    <div class="row">
      <v-chip
        v-for="x in dataset.regular"
        :key="x"
        color="green"
        text-color="green"
        outlined
        >{{ x }}</v-chip
      >

      <v-chip
        v-for="x in dataset.active"
        :key="x"
        color="green"
        text-color="white"
        >{{ x }}</v-chip
      >

      <v-chip
        v-for="x in dataset.active2"
        :key="x"
        color="orange"
        text-color="white"
        ><v-avatar left> <v-icon color="white">mdi-star</v-icon> </v-avatar
        >{{ x }}</v-chip
      >
    </div>
  </div>
</template>

<script>
import { useRulesStore } from "../store.pinia.js";

export default {
  name: "Summary",
  props: ["header"],
  data() {
    return {
      dataset: {},
      store: useRulesStore(),
    };
  },
  methods: {
    dedup() {
      let buffer = [];
      this.store.analysys.regular.forEach((x) => {
        if (!buffer.includes(x) && !this.store.analysys.active.includes(x) && !this.store.analysys.active2.includes(x)) {
          buffer.push(x);
        }
      });
      this.dataset.regular = buffer;

      buffer = [];
      this.store.analysys.active.forEach((x) => {
        if (!buffer.includes(x) && !this.store.analysys.active2.includes(x)) {
          buffer.push(x);
        }
      });
      this.dataset.active = buffer;

      buffer = [];
      this.dataset.active2 = this.store.analysys.active2;
    },
  },
  mounted() {
    this.dataset = {};
    this.dedup();
  },
};
</script>

<style scoped>
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
}
.row > * {
  margin: 0.3rem 0.5rem;
}
</style>