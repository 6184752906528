<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <div class="box-container" v-bind="attrs" v-on="on" ref="box" />
    </template>
    <span>{{value}}</span>
  </v-tooltip>
</template>

<script>
import { useRulesStore } from "../store.pinia.js";

let one_level = "rgba(0,0,0,0.1)";
let two_level = "lightgreen";
let three_level = "#cad068";
let four_level = "orange";

export default {
  name: "Boxie",
  props: ["value"],
  data(){
    return {
      store: useRulesStore(),
    }
  },
  mounted() {
    if (this.value === 0) {
      this.$refs.box.style.backgroundColor = one_level;
    } else if (this.value > this.store.active_m * 2) {
      this.$refs.box.style.backgroundColor = four_level;
    } else if (this.value > this.store.active_m) {
      this.$refs.box.style.backgroundColor = three_level;
    } else {
      this.$refs.box.style.backgroundColor = two_level;
    }
  },
};
</script>

<style>
.box-container {
  width: 1rem;
  min-width: 1rem;
  height: 1rem;
  min-height: 1rem;
  color: red;
  margin: 0 0.2rem;
  background-color: red;
  border-radius: 0.2rem;
}
</style>