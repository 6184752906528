import { defineStore } from 'pinia'
import { ConvertWeeks, AnalyzeWeeks } from "./processors/dataset.processor";

export const useRulesStore = defineStore('rules', {
    state: () => {
        return {
            regular_q: 100,
            active_m: 30,
            key: 0,
            _dates: [new Date("2022-11-1"), new Date("2022-11-31")],
            dataset: [],
            dataset_weeks: [],
            analysys: {
                unique: [],
                regular: [],
                active: [],
                active2: []
            },
            selected_server: '',
        }
    },
    actions: {
        setRules(regular_q, active_m) {
            this.regular_q = regular_q;
            this.active_m = active_m;
            this.key++;
            AnalyzeWeeks(this.dataset_weeks);
        },
        changeKey() {
            this.key++;
        },
        updateDatasetWeeks() {
            this.dataset_weeks = ConvertWeeks(this.dataset);
            AnalyzeWeeks(this.dataset_weeks);
        },
        setDates(dates) {
            this.key++;
            this._dates = dates;
        }
    },
    getters: {
        getDates() {
            return this._dates;
        }
    }
})