<template>
  <v-app>
    <v-app-bar app color="green" dark> </v-app-bar>

    <v-main>
      <div class="row">
        <Dates @change="onPickDates" />
        <Rules />
      </div>
      <v-tabs v-model="tab" align-with-title @change="store.changeKey">
        <v-tabs-slider></v-tabs-slider>
        <v-tab> Summary</v-tab>
        <v-tab> Weeks </v-tab>
        <v-tab> Days </v-tab>
      </v-tabs>
      <template v-if="tab === 0">
        <Summary :header="dates_string" :key="store.key"/>
      </template>
      <template v-if="tab === 1">
        <h4>Stage: Weeks {{ dates_string }}</h4>
        <Boxes :dataset="store.dataset_weeks" :key="store.key"/>
      </template>
      <template v-if="tab === 2">
        <h4>Stage: Days {{ dates_string }}</h4>
        <Boxes :dataset="store.dataset" :key="store.key" />
      </template>
    </v-main>
  </v-app>
</template>

<script>
import { useRulesStore } from "./store.pinia.js";
import Dates from "./components/Dates";
import { Convert } from "./processors/dataset.processor";
import Boxes from "./components/boxes.vue";
import Rules from "./components/rules.vue";
import Summary from "./components/summary.vue";
import { get_json } from "./api/fetch.js";

export default {
  name: "App",
  components: {
    Dates,
    Boxes,
    Rules,
    Summary,
  },
  data() {
    return {
      tab: null,
      store: useRulesStore(),
      dates_string: [],
      all_dates: [],
    };
  },
  methods: {
    async updateDataset() {
      let by_days = await get_json();
      let dates = this.store.getDates; 
      let c = Convert(dates[0], dates[1], by_days);

      this.store.dataset = c[0];
      this.all_dates = c[1];
      this.store.changeKey();
      this.store.updateDatasetWeeks();
    },
    onPickDates(x) {
      if (x.length !== 2) {
        return;
      }
      let start = new Date(x[0])
      let end = new Date(x[1])
      let dates = [start, end];
      this.store.setDates(dates);
      this.dates_string = `from ${start
        .toISOString()
        .substr(0, 10)} to ${end.toISOString().substr(0, 10)}`;
      this.updateDataset();
    },
  },
  async mounted() {
    this.dates_string = `?`;
    this.store.selected_server = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1
    );
    await this.updateDataset();
  },
};
</script>

<style>
.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}
h4 {
  padding-left: 1rem;
}
</style>