<template>
  <div class="container">
    <div class="rules">
      <p><b>Regular</b> user must be active for {{ regular_q }}% of weeks</p>
      <v-slider v-model="regular_q" step="5" @end="onChange"></v-slider>
      <p><b>Active</b> user must spent {{ active_m }} minutes every week...</p>
      <v-slider v-model="active_m" step="5" @end="onChange"></v-slider>
    </div>
  </div>
</template>

<script>
import { useRulesStore } from "../store.pinia.js";

export default {
  name: "Rules",
  data() {
    return {
      store: useRulesStore(),
      regular_q: 100,
      active_m: 30,
    };
  },
  methods: {
    onChange() {
      this.store.setRules(this.regular_q, this.active_m);
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
}
.rules {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
}
.rules p {
  padding: 0;
  margin: 0;
}
</style>