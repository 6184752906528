import { useRulesStore } from "../store.pinia.js";

function get_dates_between(start, end) {
    let dates = [];
    let current = start;
    let last = end;
    while (current <= last) {
        dates.push(current.toISOString().slice(0, 10));
        current.setDate(current.getDate() + 1);
    }
    return dates;
}

export function Convert(from, till, by_days) {
    let all_users = [];
    Object.keys(by_days).forEach((day) => {
        let date = new Date(day);
        if (date < from) {
            return;
        }
        if (date > till) {
            return;
        }
        Object.keys(by_days[day]).forEach((user) => {
            if (!all_users.includes(user)) {
                all_users.push(user);
            }
        });
    });
    let all_dates = get_dates_between(
        from,
        till
    );
    let prepared = {};
    all_dates.forEach((date) => {
        prepared[date] = {};
        if (by_days[date]) {
            Object.keys(by_days[date]).forEach((user) => {
                prepared[date][user] = by_days[date][user] % 60;
            });
        }
        all_users.forEach((user) => {
            if (!prepared[date][user]) {
                prepared[date][user] = 0;
            }
        });
    });
    let datasets = [];
    all_users.forEach((user) => {
        datasets.push({
            label: user,
            data: [],
        });
        Object.values(prepared).forEach((date) => {
            datasets[datasets.length - 1].data.push(date[user]);
        });
        if (
            datasets[datasets.length - 1].data.reduce((a, b) => a + b, 0) == 0
        ) {
            datasets.pop();
        }
    });
    prepared = Object.keys(prepared).map((date) => {
        let data = [];
        Object.keys(prepared[date]).forEach((user) => {
            data.push(prepared[date][user]);
        });
        return data;
    });
    return [datasets, all_dates];
}

function days_to_weeks(chunks) {
    let weeks = [];
    for (let i = 0; i < chunks.length; i += 7) {
        weeks.push(
            chunks.slice(i, i + 7).reduce((y, x) => y += x, 0)
        );
    }
    return weeks;
}

export function ConvertWeeks(dataset) {
    let new_dataset = [];

    dataset.forEach((x) => {
        new_dataset.push({
            label: x.label,
            data: days_to_weeks(x.data),
        });
    });
    return new_dataset;
}

export function AnalyzeWeeks(dataset, options) {
    let store = useRulesStore();
    let unique = [];
    let regular = [];
    let active1 = [];
    let active2 = [];

    let weeks = dataset[0].data.length;

    dataset.forEach((user) => {
        unique.push(user.label);
        let weeks_regular = 0
        let weeks_active = 0
        let weeks_active2 = 0
        user.data.forEach((week) => {
            if (week > 0) {
                weeks_regular++;
            }
            if (week > store.active_m) {
                weeks_active++;
            }
            if (week > store.active_m * 2) {
                weeks_active2++;
            }
        });
        if (weeks_regular / weeks > store.regular_q / 100) {
            regular.push(user.label);
        }
        if (weeks_active2 / weeks > store.regular_q / 100) {
            active2.push(user.label);
        }
        if (weeks_active / weeks > store.regular_q / 100) {
            active1.push(user.label);
        }
    });
    store.analysys = {
        unique: unique,
        regular: regular,
        active: active1,
        active2: active2,
    };
}