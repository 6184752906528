<template>
  <div class="boxes-container">
    <div class="boxes-line" v-for="x in prepared" :key="x.label">
      <p>{{ x.label }}</p>
      <Boxie v-for="y in x.data" :value="y" />
      <v-chip class="ma-2" color="primary" outlined pill small>
        {{ x.stats }} / {{ range }}
      </v-chip>
      <v-chip class="ma-2" color="primary" outlined pill small>
        {{ x.sum }} min
      </v-chip>
    </div>
  </div>
</template>

<script>
import Boxie from "./boxie.vue";

export default {
  name: "Boxes",
  props: ["dataset", "weeks"],
  components: {
    Boxie,
  },
  data() {
    return {
      prepared: undefined,
      range: 0,
    };
  },
  methods: {
    Regular(x) {
      let acc = 0;
      for (let i = 0; i < x.length; i++) {
        if (x[i] !== 0) {
          acc++;
        }
      }
      return acc;
    },
    sortDataset(x) {
      this.range = x[0].data.length;
      this.prepared = x;
      for (let i = 0; i < this.prepared.length; i++) {
        this.prepared[i].stats = this.Regular(this.prepared[i].data);
        this.prepared[i].sum = this.prepared[i].data.reduce((a, b) => a + b, 0);
      }
      this.prepared = x.sort((a, b) => {
        return b.stats - a.stats;
      });
    },
  },
  mounted() {
    this.sortDataset(this.dataset);
  },
};
</script>

<style scoped>
.boxes-container {
  padding: 2rem;
}
.boxes-line {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 100%;
}

.boxes-line p {
  padding: 0;
  margin: 0;
  min-width: 7rem;
}
</style>