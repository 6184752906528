import Vue from 'vue'
import App from './App'
import Vuetify from 'vuetify/lib/framework';
import { createPinia } from 'pinia'

Vue.use(Vuetify);
const pinia = createPinia()
Vue.use(pinia)

new Vue({
    vuetify: new Vuetify(),
    el: '#app',
    render: h => h(App),
    components: { App },
    template: '<App/>'
})